<script lang="ts">
  import { onMount } from "svelte";
  import Swiper from "swiper";
  import { Navigation, Pagination, Autoplay } from "swiper/modules";
  import type { SwiperOptions } from "swiper/types";
  import { generateRandomID } from "~/util/common";

  export let title: string;
  const uniqueId = generateRandomID();
  
  let swiperEl: HTMLElement;
  let swiperInstance: Swiper;
  let isBeginning = true;
  let isEnd = false;

  const handlePrevClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (swiperInstance) {
      swiperInstance.slidePrev();
    }
  };

  const handleNextClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (swiperInstance) {
      swiperInstance.slideNext();
    }
  };

  const params: SwiperOptions = {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1,
    spaceBetween: 24,
    pagination: {
      enabled: true,
      type: "fraction",
      el: `.pagination-${uniqueId}`,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 24,
        pagination: {
          type: "bullets",
          clickable: true,
        },
      }
    },
    autoplay: {
      delay: 5000,
    },
    watchOverflow: true,
    observer: true,
    observeParents: true,
    on: {
      slideChange: () => {
        if (swiperInstance) {
          isBeginning = swiperInstance.isBeginning;
          isEnd = swiperInstance.isEnd;
        }
      }
    }
  };

  onMount(() => {
    if (!swiperEl) return;
    
    setTimeout(() => {
      swiperInstance = new Swiper(swiperEl, params);
    }, 100);
  });
</script>

<svelte:head>
  <style>
    @import 'swiper/css/navigation';
    @import 'swiper/css/pagination';
  </style>
</svelte:head>

<div class="carousel-wrapper">
  <div class="row py-lg-6 py-5">
    <div class="col-12 mb-lg-5 mb-4">
      <div class="header">
        <h2 class="lift-title">{@html title}</h2>
        <slot name="filter" />
      </div>
    </div>
    <div class="col-12">
      <div class="swiper" bind:this={swiperEl}>
        <div class="swiper-wrapper">
          <slot name="swiper" />
        </div>
      </div>
      <div class="control">
        <div class="control-navigation">
          <button 
            class="navigation-button prev-{uniqueId}" 
            on:click={handlePrevClick}
            class:disabled={isBeginning}
            disabled={isBeginning}
          >
            <i class="fa fa-chevron-left" />
          </button>
          <button 
            class="navigation-button next-{uniqueId}" 
            on:click={handleNextClick}
            class:disabled={isEnd}
            disabled={isEnd}
          >
            <i class="fa fa-chevron-right" />
          </button>
        </div>
        <div class="swiper-pagination citycon-pagination pagination-{uniqueId}" />
        <div class="d-none d-md-block ml-auto">
          <slot name="link-button" />
        </div>
      </div>
      <div class="d-md-none mt-4">
        <slot name="link-button" class="d-md-none" />
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .carousel-wrapper {

    .swiper {
      width: 100%;
      overflow: hidden;
      padding-bottom: 3rem;
      visibility: hidden;
      &.swiper-initialized {
        visibility: visible;
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .navigation-button {
      width: 32px;
      height: 32px;
      border: 1px solid #000;
      border-radius: 50%;
      display: inline-flex;
      margin-right: 16px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: transparent;

      i {
        font-size: 15px;
        color: #000;
      }

      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }

    .control-navigation :global(.swiper-button-disabled) {
      opacity: 0.4;
  }

    :global(.swiper-pagination), :global(.swiper-pagination-horizontal.swiper-pagination-bullets) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 32px;
      > :global(.swiper-pagination-bullet) {
        width: 16px;
        height: 16px;
        background: transparent;
        border: 1px solid #000;
        border-radius: 0;
        margin: 0 .35rem !important;
        opacity: 1;
        transition: background-color 0.3s ease;
      }

      > :global(.swiper-pagination-bullet-active) {
        background: #000;
        border-color: #000;
      }
    }
    
    .control {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      margin-top: 2rem;
      
      @media screen and (min-width: 768px) {
        justify-content: flex-start;
      }
    }

    :global(.swiper-slide) {
      height: auto;
      width: 100%;  // Default width for mobile
      
      @media (min-width: 768px) {
        width: calc((100% - 48px) / 3) !important;
        flex-shrink: 0;  // Prevent slides from shrinking
        max-width: calc((100% - 48px) / 3);  // Set maximum width
      }
    }
  }
</style>
